import * as styles from '../AuthGate.styles';

interface AuthConfirmationProps {
  confirmationEmail: string;
  onBack(): void;
}

export const AuthConfirmation = (props: AuthConfirmationProps) => (
  <div>
    <div className={styles.sectionUnderline}>
      <h1 className={styles.heroTitle}>Magic link sent!</h1>
      <p>
        We’ve just sent an email to{' '}
        <span className={styles.highlight}>
          {props.confirmationEmail}
        </span>{' '}
        with magic link for entering NerdbordLab.
        <br /> <br />
        You can close this window.
      </p>
    </div>
  </div>
);
