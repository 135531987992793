import { toast } from 'react-toastify';

export const withTryCatch = async <K>(
  fn: (...args: any) => Promise<K>,
  notify: boolean | string
) => {
  try {
    await fn();
    const msg = typeof notify === 'string' ? notify : 'Success';
    notify && toast(msg);
  } catch (err) {
    toast(err.message, { type: 'error' });
  }
};
