import { Button, ButtonVariant } from '@/components/Button/Button';
import { ArrowLong } from '@/components/Icons/ArrowLong';
import { LogoGithub } from '@/components/Icons/LogoGithub';

import * as styles from './AuthButtonsGroup.styles';

interface AuthButtonGroupProps {
  isAuthorizing: boolean;
  onAuthGithub(): void;
  onAuthEmail(): void;
}

export const AuthButtonsGroup = (props: AuthButtonGroupProps) => (
  <div className={styles.authButtons}>
    <Button
      disabled={props.isAuthorizing}
      onClick={props.onAuthGithub}
      icon={
        !props.isAuthorizing ? (
          <LogoGithub fill={'currentColor'} scale={50} />
        ) : (
          <span />
        )
      }
    >
      {props.isAuthorizing
        ? 'Authorizing...'
        : 'Continue with GitHub'}
    </Button>
    <Button
      disabled={props.isAuthorizing}
      onClick={props.onAuthEmail}
      icon={<ArrowLong scale={120} />}
      variant={ButtonVariant.secondary}
    >
      Continue with Email
    </Button>
  </div>
);
