import Head from 'next/head';
import { PropsWithChildren } from 'react';

interface PageSEOProps {
  pageTitle: string;
  metaDesc: string;
  ogImageSrc: string;
  ogTitle?: string;
}

export const PageSEO = (props: PropsWithChildren<PageSEOProps>) => (
  <>
    <Head>
      <title>{props.pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={props.metaDesc} />
      <meta
        property="og:title"
        content={props.ogTitle || props.pageTitle}
        key="title"
      />
      <meta
        property="og:image:secure_url"
        content={props.ogImageSrc}
      />
      <meta property="og:image" content={props.ogImageSrc} />
    </Head>
    {props.children}
  </>
);
