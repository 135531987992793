import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const heroTitle = css({
  marginBottom: Spacings.S36,
  fontWeight: FontWeight.S700,
  color: Colors.gray700,
  fontSize: '40px',
});

export const highlight = css({
  fontWeight: FontWeight.S600,
});

export const sectionUnderline = css({
  paddingBottom: Spacings.S44,
  paddingTop: 50,
});

export const pageNav = css({
  marginBottom: 50,
  minHeight: 20,
});

export const btnsFooter = css({
  paddingBottom: Spacings.S24,
  borderBottom: `1px solid ${Colors.gray200}`,
});

export const nav = css({
  paddingTop: Spacings.S20,
});

export const dataButtons = css({
  display: 'flex',
  alignItems: 'center',

  span: {
    display: 'inline-block',
    fontSize: FontSize.S14,
    color: Colors.gray500,
    margin: '0 12px 0 7px',
  },
  svg: {
    transform: 'rotate(125deg)',
  },
});

export const disclaimer = css({
  fontSize: FontSize.S14,
  color: Colors.gray500,
  marginBottom: '12px',
});
