import { useGenerateLoginLink } from '@nerdbord/graphql-api';

import { authUser, getProperRedirect } from '@/utils/auth';

import { Container } from '@/components/Container/Container';

import withApollo, {
  createSsrApolloClient,
} from '@/lib/apollo-graphql/withApollo';

import { AuthButtonsGroup } from '@/components/AuthButtonsGroup/AuthButtonsGroup';
import { ButtonBack } from '@/components/ButtonBack/ButtonBack';

import { AppRoute } from '@/config/routes';
import { withTryCatch } from '@/utils/async';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { NerdbordBeta } from '@/components/Icons/NerdbordBeta';

import { AuthConfirmation } from '@/landing/components-legacy/AuthGate/elements/AuthConfirmation';
import { AuthByEmailForm } from '@/landing/components-legacy/AuthGate/forms/AuthByEmailForm/AuthByEmailForm';
import { PageSEO } from '@/landing/components-legacy/PageSEO/PageSEO';

import { NextPageContext } from 'next';

import { useGithubAuth } from '@/hooks/useGithubAuth';
import styles from '@/landing/css/login.module.css';

function Login() {
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [isWaitingForConfirmation, setWaitingForConfirmation] =
    useState(false);
  const [isAuthByEmailVisible, setAuthByEmailVisible] =
    useState(false);
  const { handleAuthByGithub, isAuthorizing } = useGithubAuth();
  const { generateLoginLink, loading } = useGenerateLoginLink();

  const { push } = useRouter();

  const handleSetAuthByEmail = () => {
    setAuthByEmailVisible(true);
  };

  const handleAuthByEmail = (email: string) => {
    withTryCatch(async () => {
      const sentTo = await generateLoginLink(email);
      setWaitingForConfirmation(true);
      setConfirmationEmail(sentTo.email);
    }, 'Success!');
  };

  const handleBack = () => {
    return isAuthByEmailVisible
      ? setAuthByEmailVisible(false)
      : push(AppRoute.home);
  };

  return (
    <PageSEO
      pageTitle={'Nerdbord - Login'}
      metaDesc={'Login to your Nerdbord'}
      ogImageSrc={'/images/nerdbord-og-cover.jpg'}
    >
      <section className={styles.loginSection}>
        <Container>
          <div className={styles.wrapper}>
            <div className={styles.authGateWrapper}>
              <div className={styles.authGateContent}>
                {isWaitingForConfirmation ? (
                  <div className={styles.authGateContent}>
                    <NerdbordBeta />
                    <AuthConfirmation
                      confirmationEmail={confirmationEmail}
                      onBack={() => push(AppRoute.home)}
                    />
                  </div>
                ) : (
                  <>
                    <NerdbordBeta />
                    <h1 className={styles.heroTitle}>
                      Login to Nerdbord
                    </h1>
                    {!isAuthByEmailVisible && (
                      <div className={styles.btnContainer}>
                        <AuthButtonsGroup
                          isAuthorizing={isAuthorizing || loading}
                          onAuthEmail={handleSetAuthByEmail}
                          onAuthGithub={handleAuthByGithub}
                        />
                      </div>
                    )}

                    {isAuthByEmailVisible && (
                      <div className={styles.btnContainer}>
                        <AuthByEmailForm
                          onAuth={handleAuthByEmail}
                          isLoading={loading}
                        />
                        <ButtonBack onClick={handleBack}>
                          Back to different login option
                        </ButtonBack>
                      </div>
                    )}
                    {/*<nav className={styles.nav}>*/}
                    {/*  <AnchorLink*/}
                    {/*    color={Colors.orange600}*/}
                    {/*    url={AppRoute.signUp}*/}
                    {/*  >*/}
                    {/*    Don’t have an account? Sign up*/}
                    {/*  </AnchorLink>*/}
                    {/*</nav>*/}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </PageSEO>
  );
}

export async function getServerSideProps(context: NextPageContext) {
  const apolloClient = createSsrApolloClient(context);

  const user = await authUser(context, apolloClient);

  if (!user) {
    return { props: {} };
  }

  const redirectTo = getProperRedirect(user.roles);

  return {
    redirect: {
      destination: redirectTo,
    },
  };
}

export default withApollo(Login);
