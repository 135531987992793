import { Spacings } from '@/styles/spacings';
import { css } from '@emotion/css';

export const wrapper = css({
  marginBottom: 56,
  maxWidth: '336px',
  button: {
    minWidth: '336px',
    // marginBottom: Spacings.S16,
    marginTop: Spacings.S16,
  },
  svg: {
    transform: 'rotate(180deg)',
  },
});
