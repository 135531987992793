import { Spacings } from '@/styles/spacings';
import { css } from '@emotion/css';

export const btn = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  svg: {
    marginRight: Spacings.S4,
  },
});
