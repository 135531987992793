import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, ButtonVariant } from '@/components/Button/Button';
import { ArrowLong } from '@/components/Icons/ArrowLong';
import { InputText } from '@/components/Input';

import * as styles from './AuthByEmailForm.styles';

interface AuthByEmailFormValues {
  email: string;
}

enum AuthByEmailFormFields {
  email = 'email',
}

export const AuthByEmailFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required('You need to pass member e-mail address.'),
});

interface AuthByEmailFormProps {
  isLoading: boolean;
  onAuth(email: string): void;
}

export const AuthByEmailForm = (props: AuthByEmailFormProps) => {
  const { values, handleSubmit, errors, touched, handleChange } =
    useFormik<AuthByEmailFormValues>({
      initialValues: {
        email: '',
      },
      onSubmit: (values) => {
        props.onAuth(values.email);
      },
      validationSchema: AuthByEmailFormSchema,
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        <InputText
          label={'Email'}
          autoFocus
          onChange={handleChange}
          value={values.email}
          placeholder={'Your email address'}
          name={AuthByEmailFormFields.email}
          error={errors.email && touched.email ? errors.email : ''}
        />
        <Button
          type={'submit'}
          disabled={props.isLoading}
          icon={<ArrowLong scale={120} />}
          variant={ButtonVariant.secondary}
        >
          Continue with Email
        </Button>
      </div>
    </form>
  );
};
