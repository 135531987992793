import { ArrowLong } from '@/components/Icons/ArrowLong';
import { Typography } from '@/components/Typography/Typography';
import { Colors } from '@/styles/colors';
import { FontSize } from '@/styles/tokens';
import { PropsWithChildren } from 'react';

import * as styles from './ButtonBack.styles';

interface ButtonBackProps {
  onClick(): void;
}

export const ButtonBack = (
  props: PropsWithChildren<ButtonBackProps>
) => (
  <button className={styles.btn} onClick={props.onClick}>
    <ArrowLong color={Colors.orange500} />
    <Typography
      Element={'p'}
      fontSize={FontSize.S14}
      color={Colors.orange500}
    >
      {props.children}
    </Typography>
  </button>
);
