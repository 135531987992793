import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { FontFamily } from '@/styles/tokens';
import { css } from '@emotion/css';

export const authButtons = css({
  marginBottom: Spacings.S56,
  button: {
    fontFamily: FontFamily.Inter,
    minWidth: '336px',
  },
  'button:first-of-type': {
    marginBottom: Spacings.S16,
    '&:hover': {
      svg: {
        path: {
          fill: Colors.gray800,
        },
      },
    },
  },
  'button:last-of-type': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
});
